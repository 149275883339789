<template>
  <div class="container">
    <h4 class="text-center my-4">Летний семестр календарь</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">



      <TabView class="mt-4">
        <TabPanel v-for="studyLevel in adviserChoose_form.studyLevels" :key="studyLevel.id" :header="studyLevel.name">

          <Button icon="pi pi-plus" label="Добавить элемент" class="p-button-rounded"
                  @click="addElement(studyLevel.id)"/>


          <div v-for="(element, elementIndex) in semesterSummerCalendar_form.semesterSummerCalendar.filter(i=>i.study_level_id == studyLevel.id)" :key="elementIndex" class="my-4 border rounded-3 p-2">


            <div class="remove-item__button d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteElement(element)"></i>
            </div>

            <div class="row mt-2 mb-2">
              <div class="col-md-5">Учебный календарь</div>
              <div class="col-md-7">

<!--                <Dropdown-->
<!--                    @change="changeEducationCalendarId(elementIndex, studyLevel.id, $event)"-->
<!--                    :options="educationCalendar_form.educationCalendar.filter(i=>i.study_level_id == studyLevel.id)"-->
<!--                    optionLabel="study_course" optionValue="id"-->
<!--                    :placeholder="element?.education_calendar_id != null ? getOptionLabel(element.education_calendar_id) : 'Выберите учебный календарь'"-->
<!--                    class="w100p"/>-->

                <select @change="changeEducationCalendarId(elementIndex, studyLevel.id, $event)" class="form-control form-select">
                  <option
                      v-for="(option, index) in [{id:0, study_level_id: '', study_course: null, name: 'Выберите '},...educationCalendar_form.educationCalendar.filter(i=>i.study_level_id == studyLevel.id)]"
                      :value="option.id" :key="index"
                      :selected="element.education_calendar_id === option.id">
                    {{ option.study_level }} {{ option.study_course }} {{ option.name }} курс
                  </option>
                </select>
<!--      :optionLabel="getOptionLabel(elementIndex, studyLevel.id)"          -->
              </div>
            </div>

<!--            <div class="row mb-2">-->
<!--              <div class="col-md-5">Уровень обучения</div>-->
<!--              <div class="col-md-7">-->
<!--                &lt;!&ndash;                <p>{{adviserChoose_form.studyLevels.find(i=>i.id == studyLevel.id).name}}</p>&ndash;&gt;-->
<!--                <Dropdown-->
<!--                    :options="adviserChoose_form.studyLevels"-->
<!--                    optionValue="name"-->
<!--                    optionLabel="name"-->
<!--                    :placeholder="adviserChoose_form.studyLevels.find(i=>i.id == studyLevel.id).name"-->
<!--                    class="w100p" disabled/>-->
<!--              </div>-->
<!--            </div>-->


            <div class="row mb-2">
              <div class="col-md-5">Начало</div>
              <div class="col-md-7">
                <input type="datetime-local" class="form-control" placeholder="Начало"
                       :value="element.start_date"
                       @change="changeDate(elementIndex, studyLevel.id, 'start_date', $event)">
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-5">Конец</div>
              <div class="col-md-7">
                <input type="datetime-local" class="form-control" placeholder="Конец"
                       :value="element.end_date"
                       @change="changeDate(elementIndex, studyLevel.id,'end_date', $event)">
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-5">Начало регистрации</div>
              <div class="col-md-7">
                <input type="datetime-local" class="form-control" placeholder="Начало"
                       :value="element.start_registration_date"
                       @change="changeDate(elementIndex, studyLevel.id, 'start_registration_date', $event)">
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-5">Конец регистрации</div>
              <div class="col-md-7">
                <input type="datetime-local" class="form-control" placeholder="Конец"
                       :value="element.end_registration_date"
                       @change="changeDate(elementIndex, studyLevel.id,'end_registration_date', $event)">
              </div>
            </div>



          </div>
          <div class="mt-3 text-center" v-if="semesterSummerCalendar_form.semesterSummerCalendar.filter(i=>i.study_level_id == studyLevel.id).length > 0">
            <Button icon="pi pi-save" label="Сохранить" class="p-button-rounded"
                    :loading="loadingSave" @click="save"/>
          </div>




        </TabPanel>
      </TabView>






    </div>





  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {
  convertDateToTimestamp,
  convertTimestampToDate,
  convertDateInputToTimestamp
} from "@/utils/helpers/date.helpers"

export default {
  name: 'SemesterSummerCalendar',
  data() {
    return {
      loading: true,
      //elements: [],

      loadingSave: false,

    }
  },
  computed: {
    ...mapState('semesterSummerCalendar', ['semesterSummerCalendar_form']),

    ...mapState('educationCalendar', ['educationCalendar_form']),
    ...mapState('adviserChoose', ['adviserChoose_form'])
  },

  methods: {

    ...mapActions('semesterSummerCalendar', ['GET_SEMESTER_SUMMER_CALENDAR','POST_SEMESTER_SUMMER_CALENDAR', 'DELETE_SEMESTER_SUMMER_CALENDAR', 'PUT_SEMESTER_SUMMER_CALENDAR']),
    ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),
    ...mapActions('educationCalendar', ['GET_EDUCATION_CALENDAR']),

    ...mapMutations('semesterSummerCalendar', ['ADD_ELEMENT', 'DELETE_ELEMENT', 'SET_EDUCATION_CALENDAR_ID', 'SET_DATE']),
    // convertDateInputToTimestamp,
    getOptionLabel(education_calendar_id) {

      console.log(education_calendar_id, 'education_calendar_id')

      let data = this.educationCalendar_form.educationCalendar.find(i=>i.id == education_calendar_id)
      return data.study_level + ' (' + data.study_course + ' курс)'
      //let data = this.educationCalendar_form.educationCalendar.find(i=>i.id == element.education_calendar_id)
      //element[elementIndex].education_calendar_id = value
      //return data[elementIndex].study_level + '(' + data[elementIndex].study_course + ' курс)'
      //return data[elementIndex].study_level + '(' + data[elementIndex].study_course + ' курс)'
      //return data
      //return element.study_level + '(' + element.study_course + ')'
      //return '123'
    },
    changeEducationCalendarId(elementIndex, studyLevelId, e) {
      let value = e.target.value
      this.SET_EDUCATION_CALENDAR_ID({elementIndex, studyLevelId, value})
    },
    changeDate(elementIndex,studyLevelId, property, e) {
      //let date = convertDateInputToTimestamp(e.target.value)
      let date = e.target.value
      this.SET_DATE({elementIndex, studyLevelId, property, date})

    },
    addElement(study_level_id) {
      this.ADD_ELEMENT(study_level_id)
    },
    async deleteElement(element) {
      console.log(element, 'delete element')
      if (element.id != null) {
        await this.DELETE_SEMESTER_SUMMER_CALENDAR(element.id)
        await this.GET_SEMESTER_SUMMER_CALENDAR()
      }
      else {
        this.DELETE_ELEMENT(element)
      }


    },

    async save() {
      this.loadingSave = true


      console.log(this.semesterSummerCalendar_form.semesterSummerCalendar.filter(i=>!i.id), 'save')


      if (this.semesterSummerCalendar_form.semesterSummerCalendar.filter(i=>!i.id).length > 0) {
        const postRes = await this.POST_SEMESTER_SUMMER_CALENDAR()
        if (postRes) {
          await this.GET_SEMESTER_SUMMER_CALENDAR()
          this.$message({title: 'Успешно сохранено'})
        }
      }
      else {
        const putRes = await this.PUT_SEMESTER_SUMMER_CALENDAR()
        if (putRes) {
          await this.GET_SEMESTER_SUMMER_CALENDAR()
          this.$message({title: 'Успешно обновлено'})
        }
      }




      this.loadingSave = false
    },


  },
  async mounted() {
    await this.GET_STUDY_LEVELS()
    await this.GET_EDUCATION_CALENDAR()
    await this.GET_SEMESTER_SUMMER_CALENDAR()

    this.loading = false
  },
}

</script>